import * as bootstrap from 'bootstrap'
import $ from 'jquery'
import axios from 'axios';
import "inputmask/dist/jquery.inputmask";

$(function(){
	let errorsObj = {};
	const errorClass = 'has-error';
	const hasValueClass = 'has-value';
	const formItemClass = '.checkout-content__input';
	const $cityInput = $('input[name="city"]');
	const $emailInput = $('input[name="email"]');
	const openClass = 'open';
	const dNoneClass = 'd-none';
	const noNpDelivery = '#no-np-delivery';
	const npDeliveryPrice = '#np-delivery-price';
	const $summaryPrice = $('.summary-price');
	const $summaryPricePickup = $('.summary-price-pickup');
	const npDepartmentNoSelected = '#np-department-no-selected';
	const requiredClass = '.required';
	const $npWarehousesInput = $('input[name="np-department"]');
	const $phoneInput = $('input[name="phone"]');
	const $puPhoneInput = $('input[name="pu-phone"]');
	const $nameInput = $('input[name="name"]');
	const $puNameInput = $('input[name="pu-name"]');
	const $surnameInput = $('input[name="surname"]');
	let searchCityTimeout;
	let searchNpWarehousesTimeout;
	const $formInput = $('.input-styles');
	const $continueOrderButton = $('.np .continue-order-button');
	const $continueOrderPickupButton = $('.pickup .continue-order-button');

	function checkForm() {
		const isNPDelivery = !$('.continue-order.np').hasClass(dNoneClass);

		if(isNPDelivery) {
			const trimmedData = {
				email: $('.input-styles[name="email"]').val().trim(),
				name: $('.input-styles[name="name"]').val().trim(),
				surname: $('.input-styles[name="surname"]').val().trim(),
				'np-department': $npWarehousesInput.attr('data-warehouse-ref') ?? "", //$('.input-styles[name="np-department"]').val().trim(),
				city: $('.input-styles[name="city"]').val().trim(),
				phone: $('.input-styles[name="phone"]').val().trim(),
			};

			let errors = {};

			// We need to set required error if any field is empty
			Object.keys(trimmedData).map(key => {
				const input = `.input-styles[name=${key}]`;

				if(trimmedData[key].length === 0) {
					errors[key] = 'required';
					$(input).parents(requiredClass).addClass(errorClass);
				} else if($(input).parents(requiredClass).hasClass(errorClass)) {
					errors[key] = 'required';
				}
			});

			// validate email
			checkEmail(errors);

			// Add required errors
			if(Object.keys(errors).length > 0) {
				Object.keys(errorsObj).forEach(key => delete errorsObj[key]);
				Object.assign(errorsObj, errors);

				$continueOrderButton.prop("disabled", true);
				return false;
			} else {
				$continueOrderButton.prop("disabled", false);
				return true;
			}
		}
	}

	function checkPickupForm() {
		const isPickUp = $('.continue-order.np').hasClass(dNoneClass);

		if(isPickUp) {
			const trimmedData = {
				email: $('.input-styles[name="email"]').val().trim(),
				'pu-name': $('.input-styles[name="pu-name"]').val().trim(),
				'pu-phone': $('.input-styles[name="pu-phone"]').val().trim(),
			};

			let errors = {};

			// We need to set required error if any field is empty
			Object.keys(trimmedData).map(key => {
				const input = `.input-styles[name=${key}]`;

				if(trimmedData[key].length === 0) {
					errors[key] = 'required';
					$(input).parents(requiredClass).addClass(errorClass);
				} else if($(input).parents(requiredClass).hasClass(errorClass)) {
					errors[key] = 'required';
				}
			});

			// validate email
			checkEmail(errors);

			// Add required errors
			if(Object.keys(errors).length > 0) {
				Object.keys(errorsObj).forEach(key => delete errorsObj[key]);
				Object.assign(errorsObj, errors);

				$continueOrderButton.prop("disabled", true);
				return false;
			} else {
				$continueOrderButton.prop("disabled", false);
				return true;
			}
		}
	}

	function checkEmail(errors = undefined) {
		if(validateEmail($emailInput.val().trim())) {
			if(errors !== undefined) {
				clearError(errors, 'email');
			}

			$emailInput.parents(requiredClass).removeClass(errorClass);
			return true;
		} else {
			if(errors !== undefined) {
				errors['email'] = 'required';
			}

			$emailInput.parents(requiredClass).addClass(errorClass);
			return false;
		}
	}

	function validateEmail($email) {
		const pattern  = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return pattern.test(String($email).toLowerCase());
	}

	function toggleNPInputs(open) {
		if(open) {
			$('#address-delivery').hide('80');
			$('#address-pickup').slideDown('200');
			$('.continue-order.np').addClass(dNoneClass);
			$('.continue-order.pickup').removeClass(dNoneClass);

			toggleDeliveryPrice(true, false, false);
		} else {
			$('#address-delivery').slideDown('200');
			$('#address-pickup').hide('80');
			$('.continue-order.np').removeClass(dNoneClass);
			$('.continue-order.pickup').addClass(dNoneClass);

			!$npWarehousesInput.hasClass(errorClass) && $npWarehousesInput.hasClass(hasValueClass) ?
				toggleDeliveryPrice(false, true, false)
				:
				toggleDeliveryPrice(false, false, true);
		}
	}

	function closeAllDropdowns() {
		$('.checkout-content__input').removeClass(openClass);
	}

	function setError(type, value) {
		let errorsObjCopy = Object.assign({}, errorsObj);

		errorsObjCopy[type] = value === undefined ? 'required' : value;
		Object.assign(errorsObj, errorsObjCopy);
	}

	function clearError(errorsObj, type) {
		let errorsObjCopy = Object.assign({}, errorsObj);

		if(errorsObj[type]) {
			delete errorsObjCopy[type];
			$continueOrderButton.prop("disabled", false);
		}

		if(Object.keys(errorsObjCopy).length === 0) {
			$continueOrderButton.prop("disabled", false);
		}

		return errorsObjCopy;
	}

	function checkValFromDropdown(input) {
		const $wrapper = input.parents(formItemClass);
		const $input = input;
		const $dropdownList = $wrapper.find('.dropdown-list').html();

		if(!$dropdownList.includes(`${$input.val()}<`)) {
			$wrapper.addClass(errorClass).find('.error-msg').text($input.attr('data-error-custom'));

			if($cityInput.parents('.city').hasClass(errorClass)) {
				$npWarehousesInput.prop('disabled', true);
			}

			setError($input.prop('name'));
		} else {
			$wrapper.removeClass(errorClass);

			if(!$cityInput.parents('.city').hasClass(errorClass)) {
				$npWarehousesInput.prop('disabled', false);
				howMuchIsNPShipping();

			}

			clearError($input.prop('name'));
		}
	}

	// How much is NP delivery

	function howMuchIsNPShipping() {
		const cityRef = $npWarehousesInput.attr('data-city-ref');
		let formData = new FormData();

		formData.append('city_recipient', cityRef);

		axios.post('/ajax/json/novaposhta-get-document-price', formData)
			.then(res => {
				const data = res.data.data[0];
				let totalCost = data.Cost + data.AssessedCost;
				let totalCostFormatted = totalCost.toLocaleString('en-US', {minimumFractionDigits: 2})

				$(npDeliveryPrice).html(`<span class='delivery-np-price'>${data.Cost} ${data.Cost > 0 ? 'грн' : ''}</span>`);
				$summaryPrice.html(`<span class="me-2">UAH</span>₴${totalCostFormatted}`);
			})
			.catch(error => {
				console.log(error);
			});

		toggleDeliveryPrice(false, true, false);
	}

	function toggleDeliveryPrice(noNp, npDelPrice, npDepNoSelected) {
		noNp ?
			$(noNpDelivery).removeClass(dNoneClass)
			:
			$(noNpDelivery).addClass(dNoneClass);

		npDelPrice ?
			$(npDeliveryPrice).removeClass(dNoneClass)
			:
			$(npDeliveryPrice).addClass(dNoneClass);

		npDepNoSelected ?
			$(npDepartmentNoSelected).removeClass(dNoneClass)
			:
			$(npDepartmentNoSelected).addClass(dNoneClass);
	}

	$(document).on('keypress', e => console.log(e.which))

	// phoneInput mask
	$phoneInput.inputmask({"mask": "+38 (099) 999 - 99 - 99", "placeholder": "_", "showMaskOnHover": false});
	$puPhoneInput.inputmask({"mask": "+38 (099) 999 - 99 - 99", "placeholder": "_", "showMaskOnHover": false});

	$(document).on('focusout', '.checkout-content__input.dropdown-wrap input', function () {
		const $wrapper = $(this).parents(formItemClass);

		$wrapper.removeClass(openClass);
	});

	$('.checkout-content__input.dropdown-wrap input').on('change', function () {
		checkValFromDropdown($(this));
	});

	//np city input

	$(document).on('click', '.checkout-content__input.city .dropdown-list li:not(.error)', function () {
		$(this).parents(formItemClass).find('input').val($(this).text());
		checkValFromDropdown($(this).parents(formItemClass).find('input'));
		$('.checkout-content__input.city').removeClass(openClass);
		$npWarehousesInput.attr('data-city-ref', $(this).attr('data-city-ref'));
	});

	$(document).on('click', '.checkout-content__input-city div', function () {
		$(this).parents('.checkout-content__input').removeClass(errorClass);
		$npWarehousesInput.val('');
		$npWarehousesInput.attr('data-warehouse-ref', '');

		$(this).parents(formItemClass).find('input').val($(this).text());
		$npWarehousesInput.attr('data-city-ref', $(this).attr('data-city-ref'));
		$npWarehousesInput.prop('disabled', false);
	});

	$cityInput.on('input', function () {
		const $inputWrapper = $(this).parents('.dropdown-wrap');
		const $this = $(this);
		const inputVal = $this.val().trim();
		let formData = new FormData();

		closeAllDropdowns();
		clearTimeout(searchCityTimeout);
		$npWarehousesInput.val('');
		$npWarehousesInput.attr('data-warehouse-ref', '');

		if($cityInput.val().length > 1) {
			formData.append('cityname', inputVal);

			searchCityTimeout = setTimeout(() => {
				axios.post('/ajax/html/novaposhta-search-cities', formData)
					.then(res => {
						$this.siblings('.dropdown-list').html(res.data);
					})
					.catch(error => {
						console.log(error);
					});
			}, 400);

			$inputWrapper.addClass(openClass);
		} else {
			$inputWrapper.removeClass(openClass);
		}
	});

	// np-department

	$npWarehousesInput.on('input', function () {
		const $this = $(this);
		const inputVal = $this.val().trim();
		let formData = new FormData();
		const $inputWrapper = $(this).parents('.dropdown-wrap');

		$npWarehousesInput.attr('data-warehouse-ref', '');

		closeAllDropdowns();
		clearTimeout(searchNpWarehousesTimeout);

		formData.append('city_ref', $this.attr('data-city-ref'));
		formData.append('find_by_string', inputVal);

		searchNpWarehousesTimeout = setTimeout(() => {
			axios.post(`/ajax/html/novaposhta-search-warehouses`, formData)
				.then(res => {
					$this.siblings('.dropdown-list').html(res.data);
					$inputWrapper.addClass(openClass);
				})
				.catch(error => console.log(error));
		}, 400);
	});

	$(document).on('click', '.checkout-content__input.np-department .dropdown-list li:not(.error)', function () {
		$npWarehousesInput.val($(this).text());
		$npWarehousesInput.attr('data-warehouse-ref', $(this).attr('data-warehouse-ref'));
		checkValFromDropdown($(this).parents(formItemClass).find('input'));
		$('.checkout-content__input.np-department').removeClass(openClass);
	});

	$formInput.on('change', function() {
		const $this = $(this);

		$this.val().length > 0 && $this.addClass(hasValueClass);
	});

	$formInput.on('blur', function() {
		const $this = $(this);

		$this.val().length === 0 && $this.removeClass(hasValueClass);
	});

	$formInput.on('focus', function() {
		const $this = $(this);

		clearError(errorsObj, $this.prop(name));
		$this.parents(requiredClass).removeClass(errorClass);
		$('.required.has-error').length === 0 && $continueOrderButton.prop("disabled", false);
	});

	$('input#name, input#surname').on('input', (event) => {
		let cursorPosition = event.target.selectionStart;
		let input = event.target.value;

		let cyrillic = /^[а-яА-ЯёЁіІїЇґҐЄє'\-\s]+$/u;
		if (true || !cyrillic.test(input)) {
			let latin = input.replace(/[^а-яА-ЯёЁіІїЇґҐЄє'\-\s]/gu, function(char) {
				let map = {
					//Triple:
					'sch': 'щ',

					//Double:
					'yo': 'є', 'zh': 'ж', 'ts': 'ц',
					'ch': 'ч', 'sh': 'ш', 'yu': 'ю',
					'ya': 'я',

					//Single:
					'a': 'а', 'b': 'б', 'v': 'в', 'h': 'г',
					'g': 'ґ', 'd': 'д', 'e': 'е',
					'z': 'з', 'y': 'и', 'i': 'і',
					'yi': 'ї', 'j': 'й', 'k': 'к', 'l': 'л',
					'm': 'м', 'n': 'н', 'o': 'о', 'p': 'п',
					'q': 'k', 'r': 'р', 's': 'с', 't': 'т',
					'u': 'у', 'f': 'ф', 'x': 'х',
				};
				return map[char.toLowerCase()] || '';
			});
			let capitalized = latin.split(' ')
				.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
			event.target.value = capitalized;

			event.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
		}
	})

	$continueOrderButton.on('click', () => {
		let checkFormResult = checkForm();
		if(checkFormResult) {
			let formData = new FormData();
			formData.append('email', $emailInput.val());
			formData.append('type', 'delivery');
			formData.append('city_recipient', $npWarehousesInput.attr('data-city-ref'));
			formData.append('city_recipient_title', $cityInput.val());
			formData.append('warehouse', $npWarehousesInput.attr('data-warehouse-ref'));
			formData.append('warehouse_title', $npWarehousesInput.val());
			formData.append('phone', $phoneInput.val());
			formData.append('name', $nameInput.val());
			formData.append('surname', $surnameInput.val());

			searchNpWarehousesTimeout = setTimeout(() => {
				axios.post(`/ajax/json/payment-form/`, formData)
					.then(res => {
						if(res.data.success == true) {
							$('#liqpay-form').html(res.data.payment_form);
							$('#liqpay-form form').submit();
						} else {
							//TODO: Errors catcher
						}
					})
					.catch(error => console.log(error));
			}, 400);
		}
	});

	$continueOrderPickupButton.on('click', () => {
		let checkFormResult = checkPickupForm();
		if(checkFormResult) {
			let formData = new FormData();
			formData.append('email', $emailInput.val());
			formData.append('type', 'pickup');
			formData.append('phone', $puPhoneInput.val());
			formData.append('name', $puNameInput.val());


			axios.post(`/ajax/json/payment-form/`, formData)
				.then(res => {
					if(res.data.success == true) {
						$('#liqpay-form').html(res.data.payment_form);
						$('#liqpay-form form').submit();
					} else {
						//TODO: Errors catcher
					}
				})
				.catch(error => console.log(error));
		}
	});

	$("input[name='deliveryType']").on('change', function(){
		let deliveryType = $(this).val();

		$('.delivery-type-selector-item').removeClass('checked');
		$(".delivery-type-selector-item[data-type='" + deliveryType + "']").addClass('checked');
	});

	$('.delivery-type-selector .delivery-type-selector-item').on('click', function(){
		let deliveryType = $(this).data('type');
		const isChecked = deliveryType !== 'novaposhta';

		toggleNPInputs(isChecked);

		$('.delivery-type-selector .delivery-type-selector-item').removeClass('checked');
		$(".delivery-type-selector .delivery-type-selector-item[data-type='" + deliveryType + "']").addClass('checked');
		$("input[name='deliveryType']").attr('checked', false);
		$("input[name='deliveryType'][value='" + deliveryType + "']").attr('checked', true);

		$summaryPrice.css('display', deliveryType == 'novaposhta' ? 'block' : 'none');
		$summaryPricePickup.css('display', deliveryType == 'self' ? 'block' : 'none');
	});

	$('.delivery-pickup .delivery-type-selector-item').on('click', function(){
		let pickup = $(this).data('type');

		$('.delivery-pickup .delivery-type-selector-item').removeClass('checked');
		$(".delivery-pickup .delivery-type-selector-item[data-type='" + pickup + "']").addClass('checked');
		$("input[name='pickup']").attr('checked', false);
		$("input[name='pickup'][value='" + pickup + "']").attr('checked', true);
	});
});

